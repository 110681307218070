import React from 'react';
import Helmet from 'react-helmet';

const Confirmation = (props) => {
  return (
    <div>
      <Helmet>
        <title>Amazon.com - Order 420-6942069-8008135</title>
        <link rel="icon" href="/amazon-favicon.ico" type="image/x-icon" />
      </Helmet>

      <img src="/img/order-confirmation.svg" style={{ width: '100vw', height: 'auto' }} />
    </div>
  );
};

export default Confirmation;
